import React, { useEffect } from 'react'
import Chart from 'chart.js/auto'
import { ageDistribution } from './ChartDatas'
import { BaseTable, percent, renderTd, singleHead, subHead } from './Shared'

const SocioEconomicData = ({ depInseeData, inseeData }) => {
  useEffect(() => {
    const ctx = document.getElementById('ageDistribution').getContext('2d')
    new Chart(ctx, ageDistribution(inseeData.age_distribution, depInseeData.age_distribution))
  }, [])

  const variationSalarie = (data) => (
    Math.round(
      (data.employed_distribution.salarie / data.employed_distribution.ens) * 100 - (data.employed_distribution.salarie_2014 / data.employed_distribution.ens_2014) * 100
    )
  )

  return (
    <div className="d-flex flex-column pdf-content-inside">
      <div>
        <div className="page-2-col mb-12">
          {
            inseeData.age_distribution.ens && (
              <BaseTable className="col-3">
                {singleHead('Population par grandes tranches d’âges', 3)}
                <tbody>
                  {subHead()}
                  <tr>
                    <td>Ensemble :</td>
                    <td>{inseeData.age_distribution.ens?.toLocaleString('fr-FR')}</td>
                    <td className="no-bold">{depInseeData.age_distribution.ens?.toLocaleString('fr-FR')}</td>
                  </tr>
                  <tr>
                    <td>0 à 14 ans :</td>
                    {percent(inseeData, 'age_distribution', 'f00')}
                    {percent(depInseeData, 'age_distribution', 'f00', 'no-bold')}
                  </tr>
                  <tr>
                    <td>15 à 29 ans :</td>
                    {percent(inseeData, 'age_distribution', 'f15')}
                    {percent(depInseeData, 'age_distribution', 'f15', 'no-bold')}
                  </tr>
                  <tr>
                    <td>30 à 44 ans :</td>
                    {percent(inseeData, 'age_distribution', 'f30')}
                    {percent(depInseeData, 'age_distribution', 'f30', 'no-bold')}
                  </tr>
                  <tr>
                    <td>45 à 59 ans :</td>
                    {percent(inseeData, 'age_distribution', 'f45')}
                    {percent(depInseeData, 'age_distribution', 'f45', 'no-bold')}
                  </tr>
                  <tr>
                    <td>60 à 74 ans :</td>
                    {percent(inseeData, 'age_distribution', 'f60')}
                    {percent(depInseeData, 'age_distribution', 'f60', 'no-bold')}
                  </tr>
                  <tr>
                    <td>75 ans ou plus :</td>
                    {percent(inseeData, 'age_distribution', 'f75')}
                    {percent(depInseeData, 'age_distribution', 'f75', 'no-bold')}
                  </tr>
                </tbody>
              </BaseTable>
            )
          }
          <div>
            <canvas id="ageDistribution" />
          </div>
        </div>

        {
          inseeData.csp_distribution.ens && (
            <BaseTable className="col-3 mb-12">
              {singleHead('Ménages selon la catégorie socioprofessionnelle')}
              <tbody>
                {subHead()}
                <tr>
                  <td>Ensemble :</td>
                  <td>{inseeData.csp_distribution.ens?.toLocaleString('fr-FR')}</td>
                  <td className="no-bold">{depInseeData.csp_distribution.ens?.toLocaleString('fr-FR')}</td>
                </tr>
                <tr>
                  <td>Agriculteurs exploitants :</td>
                  {percent(inseeData, 'csp_distribution', 'agriculteurs')}
                  {percent(depInseeData, 'csp_distribution', 'agriculteurs', 'no-bold')}
                </tr>
                <tr>
                  <td>Artisans, commerçants, chefs d'entreprise :</td>
                  {percent(inseeData, 'csp_distribution', 'artisans')}
                  {percent(depInseeData, 'csp_distribution', 'artisans', 'no-bold')}
                </tr>
                <tr>
                  <td>Cadres et professions intellectuelles supérieures :</td>
                  {percent(inseeData, 'csp_distribution', 'cadres')}
                  {percent(depInseeData, 'csp_distribution', 'cadres', 'no-bold')}
                </tr>
                <tr>
                  <td>Professions intermédiaires :</td>
                  {percent(inseeData, 'csp_distribution', 'intermediaires')}
                  {percent(depInseeData, 'csp_distribution', 'intermediaires', 'no-bold')}
                </tr>
                <tr>
                  <td>Employés :</td>
                  {percent(inseeData, 'csp_distribution', 'employes')}
                  {percent(depInseeData, 'csp_distribution', 'employes', 'no-bold')}
                </tr>
                <tr>
                  <td>Ouvriers :</td>
                  {percent(inseeData, 'csp_distribution', 'ouvriers')}
                  {percent(depInseeData, 'csp_distribution', 'ouvriers', 'no-bold')}
                </tr>
                <tr>
                  <td>Retraités :</td>
                  {percent(inseeData, 'csp_distribution', 'retraites')}
                  {percent(depInseeData, 'csp_distribution', 'retraites', 'no-bold')}
                </tr>
                <tr>
                  <td>Autres personnes sans activité professionnelle :</td>
                  {percent(inseeData, 'csp_distribution', 'autres')}
                  {percent(depInseeData, 'csp_distribution', 'autres', 'no-bold')}
                </tr>
              </tbody>
            </BaseTable>
          )
        }

        <BaseTable className="col-3 mb-12">
          {singleHead('Revenus')}
          <tbody>
            {subHead()}
            <tr>
              <td>Nombre de ménages fiscaux :</td>
              <td>{inseeData.revenue_data.fiscal_households?.toLocaleString('fr-FR')}</td>
              <td className="no-bold">{depInseeData.revenue_data.fiscal_households?.toLocaleString('fr-FR')}</td>
            </tr>
            <tr>
              <td>Part des ménages fiscaux imposés :</td>
              {renderTd(inseeData.taxed_households.ens, '%')}
              {renderTd(depInseeData.taxed_households.ens, '%', 'no-bold')}
            </tr>
            <tr>
              <td>Taux de pauvreté :</td>
              {renderTd(inseeData.poverty_rate.ens, '%')}
              {renderTd(depInseeData.poverty_rate.ens, '%', 'no-bold')}
            </tr>
            <tr className="empty" />
            <tr>
              <td>Médiane du revenu disponible par unité de consommation ** :</td>
              {renderTd(inseeData.revenue_data?.median?.toLocaleString('fr-FR'), '€')}
              {renderTd(depInseeData.revenue_data.median?.toLocaleString('fr-FR'), '€', 'no-bold')}
            </tr>
            <tr>
              <td>Salaire net mensuel médian par unité de consommation :</td>
              {renderTd(inseeData.revenue_data?.median ? Math.round((inseeData.revenue_data.median || 0) / 12).toLocaleString('fr-FR') : null, '€')}
              {renderTd(depInseeData.revenue_data?.median ? Math.round(depInseeData.revenue_data.median / 12).toLocaleString('fr-FR') : null, '€', 'no-bold')}
            </tr>
          </tbody>
        </BaseTable>

        <BaseTable className="col-3 mb-12">
          {singleHead('Emploi – Chômage au sens du recensement')}
          <tbody>
            {subHead()}
            <tr>
              <td>Nombre d'actifs de 15 à 64 ans :</td>
              {renderTd(inseeData.activity_distribution.employed ? (inseeData.activity_distribution.employed + inseeData.activity_distribution.unemployed || 0).toLocaleString('fr-FR') : null)}
              {renderTd(depInseeData.activity_distribution.employed ? (depInseeData.activity_distribution.employed + depInseeData.activity_distribution.unemployed || 0).toLocaleString('fr-FR') : null, '', 'no-bold')}
            </tr>
            <tr>
              <td className="italic">Part de l'emploi salarié :</td>
              {percent(inseeData, 'employed_distribution', 'salarie', 'no-bold italic')}
              {percent(depInseeData, 'employed_distribution', 'salarie', 'no-bold italic')}
            </tr>
            <tr>
              <td>Variation de l'emploi salarié entre 2014 et 2020 :</td>
              {renderTd(variationSalarie(inseeData), 'pts', 'no-bold')}
              {renderTd(variationSalarie(depInseeData), 'pts', 'no-bold')}
            </tr>
            <tr className="empty" />
            <tr>
              <td>Taux d'actifs des 15 à 64 ans ayant un emploi *** :</td>
              {percent(inseeData, 'activity_distribution', 'employed')}
              {percent(depInseeData, 'activity_distribution', 'employed', 'no-bold')}
            </tr>
            <tr>
              <td>Taux de chômage des 15 à 64 ans :</td>
              {percent(inseeData, 'activity_distribution', 'unemployed')}
              {percent(depInseeData, 'activity_distribution', 'unemployed', 'no-bold')}
            </tr>
          </tbody>
        </BaseTable>
      </div>
      <div className="asterich">
        <div>
          <span className="color-candy-primary">*</span>
          Données issues du recensement INSEE 2020
          <br />
          <span className="color-candy-primary">**</span>
          Pour comparer les niveaux de vie de ménages de taille ou de composition différente, on divise le
          <br />
          revenu par le nombre d’unités de consommation (UC).
          <br />
          <span className="color-candy-primary">***</span>
          La catégorie des “actifs” exclue : élèves, étudiants et stagiaires, retraités, pré-retraités et inactifs.
        </div>
      </div>
    </div>
  )
}

export default SocioEconomicData
